<template>
  <div :class="`m-3 wrapper wrapper--border wrapper--${bu}`">
    <div class="text-center p-2">
      <h2>Resultaten per verkoper</h2>
      <span @click="previousYear"><i class="fas fa-arrow-left"></i></span>
      <span class="text-center m-2">Jaar: {{ year_number }}</span>
      <span @click="nextYear"><i class="fas fa-arrow-right"></i></span>
    </div>
    <Loading v-if="loading" />
    <b-card v-else no-body>
      <b-tabs
        content-class="mt-3"
        justified
        pills
        active-nav-item-class="active-nav-class"
        nav-class="nav-control-class"
      >
        <b-tab title="Jaar" active>
          <div class="p-2">
            <h2 class="text-center">Resultaten per jaar</h2>
            <ResultsPerSellerTable
              :table_data="seller_results_per_year"
              :table_data_sum="seller_results_per_year_sum"
              :table_style="bu"
              :year_number="year_number"
            />
          </div>
        </b-tab>
        <b-tab title="Kwartaal" @click="loadQuarterData(bu)">
          <Loading v-if="quarter_loading" />
          <div
            v-else
            v-for="(quarter, key) in seller_results_per_quarter"
            :key="key"
            class="p-2"
          >
            <h2 class="text-center">{{ key }}</h2>
            <ResultsPerSellerTable
              :table_data="quarter.data"
              :table_data_sum="quarter.totals"
              :table_style="bu"
              :year_number="year_number"
            />
          </div>
        </b-tab>
        <b-tab title="Maand" @click="loadMonthData(bu)">
          <Loading v-if="month_loading" />
          <div
            v-else
            v-for="(months, key) in seller_results_per_month"
            :key="key"
          >
            <div v-if="months.data" class="pt-2">
              <h2 class="text-center">{{ key }}</h2>
              <ResultsPerSellerTable
                :table_data="months.data"
                :table_data_sum="months.totals"
                :table_style="bu"
                :year_number="year_number"
              />
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
import request from "@/functions/request.js";
import ResultsPerSellerTable from "@/components/ResultsPerSellerTable.vue";
import moment from "moment";
import { addTime, subtractTime, getYearNumber } from "@/functions/date.js";

export default {
  props: ["bu"],
  components: { Loading, ResultsPerSellerTable },
  data: () => ({
    loading: true,
    quarter_loading: true,
    month_loading: true,
    year_number: null,
    full_date: null,
    seller_results_per_year: null,
    seller_results_per_year_sum: null,
    seller_results_per_quarter: null,
    seller_results_per_month: null,
  }),
  created() {
    this.full_date = moment();
    this.getData(this.bu);
  },
  watch: {
    bu(newbu) {
      this.loading = true;
      this.quarter_loading = true;
      this.month_loading = true;
      this.getData(newbu);
    },
  },
  methods: {
    getData(bu) {
      this.year_number = getYearNumber(this.full_date);
      request(`results-per-seller/${bu}/${this.year_number}`, "GET").then(
        ({ seller_results_per_year }) => {
          this.seller_results_per_year = seller_results_per_year.data;
          this.seller_results_per_year_sum = seller_results_per_year.totals;
          this.loading = false;
        }
      );
    },

    loadQuarterData(bu) {
      request(
        `results-per-seller-per-quarter/${bu}/${this.year_number}`,
        "GET"
      ).then(({ seller_results_per_quarter }) => {
        this.seller_results_per_quarter = seller_results_per_quarter;
        this.quarter_loading = false;
      });
    },

    loadMonthData(bu) {
      request(
        `results-per-seller-per-month/${bu}/${this.year_number}`,
        "GET"
      ).then(({ seller_results_per_month }) => {
        this.seller_results_per_month = seller_results_per_month;
        this.month_loading = false;
      });
    },

    previousYear() {
      this.loading = true;
      this.quarter_loading = true;
      this.month_loading = true;
      this.full_date = subtractTime(1, this.full_date, "years");
      this.getData(this.bu);
    },

    nextYear() {
      this.loading = true;
      this.quarter_loading = true;
      this.month_loading = true;
      this.full_date = addTime(1, this.full_date, "years");
      this.getData(this.bu);
    },
  },
};
</script>